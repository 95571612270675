import React from 'react';
import Link from 'gatsby-link';
import styled from 'styled-components';
import Layout from '../layouts'

const StyledLink = styled(Link)`
  color: #D3D3D3;
  padding: 2px;
  &:hover {
    text-decoration: none;
    color: #D3D3D3;
  }
`;

export default ({ data }) => {
  return (
    <Layout>

      <h1>Välkommen</h1>

      <p>Här har vi valt att samla all information i syfte att stödja dig som medarbetare på Evolve med den informationen du behöver i ditt dagliga arbete. Vi anser att en väl fungerande intern kommunikation är A och O för att vår organisation på Evolve skall kunna fungera och utvecklas. Det handlar om att skapa en enhetlighet och en gemensam plats för viktig information, helt enkelt en användarvänlig plattform där all information som rör Evolve och dig som medarbetare finns samlad.</p>

      <p>Här får du som ny medarbetare och som gammal i gemet möjligheten att få information om allt ifrån kommande events, vår startup portfolio, vad som gäller kring försäkringar till vår rekryteringsprocess. </p>
     
      <img src="https://www.file-roulette.com/l/7b14346b305330d07916d051d3b8db89?mode=random" alt="Random images from Evolve"></img>

    </Layout>
  );
};
